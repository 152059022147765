@import '~@JohnLewisPartnership/jl-design-system/dist/style/typography/display-as';
@import '~@JohnLewisPartnership/jl-design-system/dist/style/colour/john-lewis/variables';
@import '~@JohnLewisPartnership/jl-design-system/dist/scss/core/spacing';
@import '~@JohnLewisPartnership/jl-design-system/dist/scss/grid/grid';
@import '~@JohnLewisPartnership/jl-design-system/dist/scss/grid/breakpoints';
@import '../../../scss/utilities.scss';

@import '../../../scss/base';

.flyoutMenu {
  border: 1px solid $colour-grey-15;
  border-top: none;
  background-color: $colour-white;
  left: 50%;
  margin: 1px auto 0;
  padding: spacing(4) spacing(3) spacing(1);
  position: absolute;
  transform: translateX(-50%);
  width: 100%;
  max-width: 1248px;
  cursor: default;

  &__fullWidth {
    width: calc(100% + 0.9px);
    max-width: none;
    border-right: none;
    border-left: none;
  }

  @media (min-width: 1440px) {
    max-width: 1360px;
  }
  @media (min-width: 1580px) {
    max-width: 1440px;
  }

  &__grouped {
    background-color: $colour-white;
    border: none;
    max-width: initial;
  }

  &__column {
    flex-basis: 16.666%;

    &.twoCol {
      flex-basis: 32.3332%;
      ul {
        columns: 2;
      }
    }

    &__grouped {
      display: flex;
      flex-direction: column;
    }

    &:not(:last-child) {
      padding-right: spacing(3);
    }
  }

  ul {
    padding: 0;
    margin: 0;
    list-style-type: none;
  }

  .close {
    position: absolute;
    background-color: transparent;
    margin-bottom: 0;
    min-width: spacing(3);
    padding: 0;
    top: spacing();
    right: spacing();
  }

  &__heading {
    &__clickable {
      h4 {
        cursor: pointer;
      }

      text-decoration: none;
    }
  }
}

.flyoutMenuColumns {
  display: flex;
  flex-direction: row;
  flex-basis: 208px;
  max-width: calc(100% - 40px);
  margin: auto;
}

.flyoutMenuBanner {
  flex-basis: 100%;
  margin: spacing(2) 0 spacing(1);

  &__fullWidth {
    margin-left: auto;
    margin-right: auto;
    max-width: 1200px;
  }

  &__grouped {
    margin-left: auto;
    margin-right: auto;
    max-width: 1232px;
  }
}

.flyoutMenuTitle {
  @include display-as('heading-xs');
  border-bottom: 1px solid $colour-black;
  text-align: left;
  margin-bottom: spacing(2);
  padding-bottom: spacing(2);

  &__no_underline {
    border-bottom: none;
  }

  &__grouped {
    margin-bottom: 0;
    padding-bottom: 20px;
    border: none;
  }
}

.imageBlock {
  display: flex;
  flex-direction: column;
  text-decoration: none;
}

.visuallyHidden {
  @include visuallyHidden;
}

.saleOffersLabel {
  color: $colour-offers;
}
