@import '~@JohnLewisPartnership/jl-design-system/dist/style/typography/display-as';
@import '~@JohnLewisPartnership/jl-design-system/dist/style/colour/john-lewis/variables';
@import '~@JohnLewisPartnership/jl-design-system/dist/scss/core/spacing';
@import '~@JohnLewisPartnership/jl-design-system/dist/scss/grid/grid';
@import '~@JohnLewisPartnership/jl-design-system/dist/scss/grid/breakpoints';
@import '../../scss/utilities.scss';

@import '../../scss/base.scss';

:global(.jl-esi-header) {
  @include breakpoint-between(0, medium) {
    position: sticky;
    z-index: 99;
    top: 0;
    pointer-events: none;

    :global(#pecr-cookie-banner-wrapper) {
      pointer-events: auto;
    }
  }
}

.siteWide {
  margin-bottom: 0;
  position: relative;
  z-index: 20;
  @include clearfix();
  @include breakpoint('small') {
    position: relative;
  }
}

.headerContainer {
  @include breakpoint('medium') {
    background: $colour-white;
  }
}
