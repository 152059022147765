@import '~@JohnLewisPartnership/jl-design-system/dist/style/typography/display-as';
@import '~@JohnLewisPartnership/jl-design-system/dist/style/colour/john-lewis/variables';
@import '~@JohnLewisPartnership/jl-design-system/dist/scss/core/spacing';
@import '~@JohnLewisPartnership/jl-design-system/dist/scss/grid/grid';
@import '~@JohnLewisPartnership/jl-design-system/dist/scss/grid/breakpoints';
@import '../../scss/utilities.scss';

@import '../../scss/base';
@import '../responsive-header/typeahead-transition-utils.scss';

.overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
}

.transparentOverlay {
  @extend .overlay;
  display: none;
  height: 100vh;
  height: calc(var(--vh, vh) * 100);
  background-color: $colour-black;
  z-index: 124;

  visibility: visible;
  opacity: 0.567;

  &__hidden {
    width: 100%;
    height: 0;
    visibility: hidden;
    opacity: 0;
  }

  @include breakpoint(medium) {
    display: block;
  }
}

.whiteOverlay {
  @extend .overlay;
  height: 100vh;
  height: calc(var(--vh, vh) * 100);
  background-color: $colour-white;
  z-index: 125;

  @include breakpoint(medium) {
    height: 93vh;
    height: calc(var(--vh, vh) * 93);
  }

  visibility: visible;

  &__hidden {
    width: 100%;
    height: 0;
    visibility: hidden;
    opacity: 0;
  }
}

.closeButton {
  z-index: 126;
  position: absolute;
  right: 0;
  cursor: pointer;
  background-color: transparent;
  padding: 0;
  border: 0;
  @include grid-col(1);
  @include breakpoint-between(0, medium) {
    margin: spacing(2);
    width: auto;
  }

  &:hover {
    opacity: 0.4;
  }

  visibility: visible;
  opacity: 1;

  &__hidden {
    visibility: hidden;
    opacity: 0 !important;
  }

  p {
    margin-bottom: 0;
    font-weight: $font-weight-regular;
  }
}

.closeIcon {
  display: block;
  margin: spacing(0.5) auto 0;
  width: auto;
  height: auto;
}

.bpOverlay {
  width: 100%;
  height: 100vh;
  position: absolute;
  background-color: $colour-sand-20;
  border-top: 1px solid $colour-grey-15;
  z-index: 99;

  &__hidden {
    display: none;
  }

  &__white {
    background-color: $colour-white;
  }

  @include breakpoint(medium) {
    max-height: 743px;
  }
}

.bpTransparentOverlay {
  width: 100vw;
  position: fixed;
  height: calc(var(--vh, vh) * 100);
  background-color: $colour-black;
  z-index: 98;
  visibility: visible;
  opacity: 0.567;
  transition-property: opacity, height;
  transition-duration: $typeaheadOpeningTransDur, 0s;
  transition-timing-function: $typeaheadTransTiming;
  transition-delay: 0.2s, 0s;

  &__hidden {
    width: 100%;
    height: 0;
    visibility: hidden;
    opacity: 0;
  }
}

.bpCloseButton {
  position: relative;
  z-index: 126;
  cursor: pointer;
  background-color: transparent;
  padding: 0;
  border: 0;
  flex: 0 0 60px;
  line-height: 1;
  height: 35px;
  margin-top: -4px;
  order: 1;

  &__hidden {
    display: none;
  }

  &__desktop {
    @include breakpoint-between(0, medium) {
      display: none;
    }
  }

  &__mobile {
    @include breakpoint(medium) {
      display: none;
    }
  }
  img {
    margin: 0 auto;
  }

  p {
    font-size: 10px;
    line-height: 14px;
    margin-top: 1px;
    margin-bottom: 0;
  }

  @include breakpoint(small) {
    height: 48px;
    margin-top: 0;
    order: 0;
  }

  @include breakpoint(medium) {
    flex: 0 0 40px;
    order: 2;

    p {
      font-size: 16px;
      line-height: 20px;
    }
  }
}
