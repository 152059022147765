@import '~@JohnLewisPartnership/jl-design-system/dist/style/typography/display-as';
@import '~@JohnLewisPartnership/jl-design-system/dist/style/colour/john-lewis/variables';
@import '~@JohnLewisPartnership/jl-design-system/dist/scss/core/spacing';
@import '~@JohnLewisPartnership/jl-design-system/dist/scss/grid/grid';
@import '~@JohnLewisPartnership/jl-design-system/dist/scss/grid/breakpoints';
@import '../../scss/utilities.scss';

@import '../../scss/base';

.link {
  display: block;
  height: 100%;
  text-decoration: none;
  width: 100%;

  @include breakpoint(medium) {
    padding: spacing();
    display: flex;
    align-items: center;
    height: spacing(6);
    min-width: 40px;
  }

  @include breakpoint(large) {
    display: flex;
    height: spacing(6);
    align-items: center;
  }
}

.label {
  @extend %nav-icon-label;
}
