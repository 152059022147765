@import '~@JohnLewisPartnership/jl-design-system/dist/style/typography/display-as';
@import '~@JohnLewisPartnership/jl-design-system/dist/style/colour/john-lewis/variables';
@import '~@JohnLewisPartnership/jl-design-system/dist/scss/core/spacing';
@import '~@JohnLewisPartnership/jl-design-system/dist/scss/grid/grid';
@import '~@JohnLewisPartnership/jl-design-system/dist/scss/grid/breakpoints';
@import '../../scss/utilities.scss';

@import '../../scss/base';

.visuallyHidden {
  @include visuallyHidden;
}

.nav {
  padding: 0;
  margin-top: -4px;
  margin-right: 15px;

  @include breakpoint(small) {
    margin-top: 6px;
    margin-right: 12px;
  }

  @include breakpoint(medium) {
    margin-top: 0;
  }

  @include breakpoint(large) {
    margin-right: 0;
  }

  &__active {
    display: none;
  }

  &__tn {
    @include breakpoint(small) {
      margin-top: 0;
    }

    @include breakpoint(1200) {
      height: auto;
      margin-right: 0;
      margin-top: 0;
    }
  }
}

.navList {
  list-style: none;
  margin: 0;
  padding: 0;
  width: 100%;
  display: flex;
}

.navListItem {
  display: flex;
  height: auto;
  align-items: center;
  justify-content: center;
  margin-bottom: 0;
  margin-right: 0;
  position: relative;
  text-align: center;
  width: auto;

  &:first-child {
    margin-right: spacing(2);
  }

  &:nth-child(n) {
    margin-right: 18px;
  }

  @include breakpoint(xxs) {
    &:nth-child(n) {
      margin-right: 20px;
    }
  }

  @include breakpoint(small) {
    &:nth-child(n) {
      margin-right: 24px;
    }
  }

  @include breakpoint(medium) {
    &:nth-child(n) {
      margin-right: 0;
    }
  }

  &:last-child {
    margin-right: 2px;
  }

  &__tn {
    @include breakpoint(medium) {
      width: 38px;
      &:first-child {
        margin-right: 16px;
      }

      &:nth-child(2) {
        margin-right: 10px;
      }
    }

    @include breakpoint(1200) {
      width: auto;
      &:first-child {
        margin-right: 0;
      }

      &:nth-child(2) {
        margin-right: 0;
      }
    }
  }
}

.navListItemLink {
  display: block;
  height: 100%;
  text-decoration: none;
  width: 100%;
  -ms-touch-action: manipulation;
  touch-action: manipulation;
}

.navListItemLinkButton {
  display: block;
  height: 100%;
  text-decoration: none;
  width: 100%;
  -ms-touch-action: manipulation;
  touch-action: manipulation;
  border: none;
  background-color: $colour-white;
  padding: 0;
}

.navListItemLabel {
  @extend %nav-icon-label;
}

.navListLabel {
  &__tn {
    @include breakpoint(medium) {
      display: block;
    }

    @include breakpoint(1200) {
      display: none;
    }
  }
}

.menu {
  @extend %jb-icon-height;

  @include breakpoint(medium) {
    display: none;
  }

  &__tn {
    @include breakpoint(medium) {
      display: flex;
      align-items: center;
      width: 38px;
    }

    @include breakpoint(1200) {
      display: none;
    }

    div {
      @include breakpoint(medium) {
        height: 28px;
        display: block;
      }
    }
  }
}

.storeFinder {
  @extend %jb-icon-height;

  @include breakpoint(medium) {
    display: none;
  }

  &__mobile {
    display: flex;
  }
}
