@import '~@JohnLewisPartnership/jl-design-system/dist/style/typography/display-as';
@import '~@JohnLewisPartnership/jl-design-system/dist/style/colour/john-lewis/variables';
@import '~@JohnLewisPartnership/jl-design-system/dist/scss/core/spacing';
@import '~@JohnLewisPartnership/jl-design-system/dist/scss/grid/grid';
@import '~@JohnLewisPartnership/jl-design-system/dist/scss/grid/breakpoints';
@import '../../../scss/utilities.scss';

@import '../../../scss/base.scss';

.flyoutMenuItem {
  list-style-type: none;
  margin-bottom: spacing(2);
  text-align: left;
  break-inside: avoid;

  a {
    text-decoration: none;
    color: $colour-black;

    &:hover {
      text-decoration: underline;
    }
  }

  &__grouped {
    margin-bottom: 12px;
  }
}
