@import '~@JohnLewisPartnership/jl-design-system/dist/style/typography/display-as';
@import '~@JohnLewisPartnership/jl-design-system/dist/style/colour/john-lewis/variables';
@import '~@JohnLewisPartnership/jl-design-system/dist/scss/core/spacing';
@import '~@JohnLewisPartnership/jl-design-system/dist/scss/grid/grid';
@import '~@JohnLewisPartnership/jl-design-system/dist/scss/grid/breakpoints';
@import '../../../scss/utilities.scss';

@import '../../../scss/base';

.groupedFlyoutMenu {
  background-color: $colour-white;
  left: 50%;
  margin: 1px auto 0;
  padding: spacing(4) spacing(3) spacing(1);
  position: absolute;
  transform: translateX(-50%);
  width: 100%;

  &__column {
    display: flex;
    flex-direction: column;
    flex-basis: 208px;
    padding: 0;
    margin: 0;
    list-style-type: none;
  }

  &__group {
    margin-bottom: 0;

    &__links {
      margin: 0;
      padding: 0 0 40px 0;

      li:not(:last-child) {
        margin-bottom: 12px;
      }

      li:last-child {
        margin-bottom: 0;
      }
    }
  }

  .close {
    position: absolute;
    background-color: transparent;
    margin-bottom: 0;
    min-width: spacing(3);
    padding: 0;
    top: spacing(2);
    right: spacing(2);

    &:hover {
      background-color: transparent;
      border-radius: 0;
    }
  }
}

.groupedFlyoutMenuColumns {
  display: flex;
  flex-direction: row;
  justify-content: center;
  max-width: 1200px;
  margin: auto;
}

.groupedFlyoutMenuBanner {
  display: block;
  margin: 0 auto 16px;
  max-width: 1232px;
}

.groupedFlyoutMenuTitle {
  @include display-as('heading-xs');
  text-align: left;
  padding-bottom: spacing(2) + 4;
  padding-right: 12px;
  margin-bottom: 0;
}

.saleOffersLabel {
  color: $colour-offers;
}
