@mixin visuallyHidden() {
  position: absolute;
  width: 1px;
  height: 1px;
  padding: 0;
  margin: -1px;
  overflow: hidden;
  clip: rect(0, 0, 0, 0);
  border: 0;
}

%nav-icon-label {
  display: block;
  width: 100%;
  height: ($font-size-caption - 4);
  text-decoration: none;
  text-align: center;
  font-size: ($font-size-caption - 4);
  font-weight: $font-weight-regular;
  line-height: ($font-size-caption - 1);
  margin-top: -3px;

  @include breakpoint(medium) {
    display: none;
  }
}

%jb-icon-height {
  div {
    height: spacing(3) + 4;
    display: block;

    @include breakpoint(medium) {
      height: spacing(3);
      display: inline-block;
    }
  }
}
