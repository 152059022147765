@import '~@JohnLewisPartnership/jl-design-system/dist/style/typography/display-as';
@import '~@JohnLewisPartnership/jl-design-system/dist/style/colour/john-lewis/variables';
@import '~@JohnLewisPartnership/jl-design-system/dist/scss/core/spacing';
@import '~@JohnLewisPartnership/jl-design-system/dist/scss/grid/grid';
@import '~@JohnLewisPartnership/jl-design-system/dist/scss/grid/breakpoints';
@import '../../scss/utilities.scss';

@import '../../scss/base';

.container {
  display: block;
  text-decoration: none;
  height: auto;
  width: 38px;

  @include breakpoint(medium) {
    padding: spacing();
    display: flex;
    align-items: center;
    width: auto;
    margin-right: 10px;
    height: spacing(6);
    min-width: 40px;

    &:hover {
      text-decoration: none;
    }
  }

  div {
    min-width: 24px;
  }

  @extend %jb-icon-height;

  &__tn {
    display: block;
    margin-right: 0;

    @include breakpoint(medium) {
      padding: 0;
      width: 38px;
      height: auto;
      min-width: 38px;

      div {
        display: block;
        height: 28px;
      }
    }

    @include breakpoint(1200) {
      display: flex;
      height: spacing(6);
      width: auto;
      margin-right: 10px;
      min-width: 40px;
      padding: 8px;

      div {
        height: spacing(3);
        display: inline-block;
      }
    }
  }
}

.greeting {
  display: none;
  color: $colour-black;
  margin-top: 4px;
  @include breakpoint(medium) {
    display: block;
    margin-right: spacing();
  }

  &__tn {
    @include breakpoint(medium) {
      display: none;
    }

    @include breakpoint(1200) {
      display: block;
      margin-right: spacing();
    }
  }
}

.label {
  @extend %nav-icon-label;

  &__tn {
    @include breakpoint(medium) {
      display: block;
    }

    @include breakpoint(1200) {
      display: none;
    }
  }
}
