@import '~@JohnLewisPartnership/jl-design-system/dist/style/typography/display-as';
@import '~@JohnLewisPartnership/jl-design-system/dist/style/colour/john-lewis/variables';
@import '~@JohnLewisPartnership/jl-design-system/dist/scss/core/spacing';
@import '~@JohnLewisPartnership/jl-design-system/dist/scss/grid/grid';
@import '~@JohnLewisPartnership/jl-design-system/dist/scss/grid/breakpoints';
@import '../../../scss/utilities.scss';

@import '../../../scss/base.scss';

.desktopMenu {
  border-top: 0;
  border-bottom: 1px solid $colour-grey-15;
  background-color: $colour-white;
  width: 100%;
  position: relative;
  top: 0;
  max-width: 100%;
  padding-bottom: 0;
  height: spacing(7)-2;

  @include breakpoint-between(0, medium) {
    display: none;

    // prevent links from getting tab focus when not visible
    a {
      display: none;
    }
  }

  &__tn {
    height: auto;

    &__active {
      border: none;
    }

    @include breakpoint-between(0, 1200) {
      display: none;

      a {
        display: none;
      }
    }
  }
}

.desktopMenuList {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  max-width: 1200px;
  margin: auto;
  padding-left: 0;
  padding-right: 0;

  &__tn {
    @include breakpoint(1200) {
      width: calc(100vw - 48px);
      max-width: 1320px;
    }

    @include breakpoint(large) {
      width: calc(100vw - 64px);
    }
  }
}

.desktopMenuOverlay {
  display: none;

  @include breakpoint(medium) {
    display: block;
    position: fixed;
    top: spacing(10);
    left: 0;
    right: 0;
    bottom: 0;
    opacity: 0;
    background: $colour-black;
    transition: opacity 0.6s 0.1s ease-in-out, visibility 0s 0.5s;
    visibility: hidden;
    z-index: -1;
  }

  &__active {
    @include breakpoint(medium) {
      opacity: 0.567;
      transition: opacity 0.5s;
      visibility: visible;
    }
  }

  &__tn {
    @include breakpoint(medium) {
      display: none;
    }

    @include breakpoint(1200) {
      display: block;
      top: 160px;
    }

    &__active {
      @include breakpoint(1200) {
        opacity: 0.567;
        transition: opacity 0.5s;
        visibility: visible;
      }
    }
  }
}
