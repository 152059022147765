@import '~@JohnLewisPartnership/jl-design-system/dist/style/typography/display-as';
@import '~@JohnLewisPartnership/jl-design-system/dist/style/colour/john-lewis/variables';
@import '~@JohnLewisPartnership/jl-design-system/dist/scss/core/spacing';
@import '~@JohnLewisPartnership/jl-design-system/dist/scss/grid/grid';
@import '~@JohnLewisPartnership/jl-design-system/dist/scss/grid/breakpoints';
@import '../../../scss/utilities.scss';

@import '../../../scss/base.scss';

.desktopMenuItem {
  list-style-type: none;
  border-bottom: 1px solid $colour-grey-15;
  margin: 0;
  border: none;
  display: block;
  text-align: center;
  flex-grow: 1;

  .offersLabel {
    color: $colour-offers;
  }

  @include breakpoint(large) {
    &:first-child {
      flex-grow: 0;
      padding-right: spacing(2);
    }

    &:last-child {
      flex-grow: 0;
      padding-left: spacing(2);
    }
  }

  cursor: pointer;
  @include breakpoint(medium) {
    &:first-child {
      flex-grow: 0;
      padding-right: spacing(2);
    }

    &:last-child {
      flex-grow: 0;
      padding-left: spacing(2);
    }
  }

  @include breakpoint(1440) {
    &:first-child {
      padding-right: spacing(3);
    }

    &:last-child {
      padding-left: spacing(3);
    }
  }

  .desktopMenuItemLink {
    padding: spacing(2)-2 0 spacing(2)-3;
  }

  .desktopMenuItemLink:hover,
  &__active .desktopMenuItemLink {
    border-bottom-color: $colour-accent;
    border-bottom-width: 4px;
    padding-bottom: 10px;
  }
}

.desktopMenuItemLink,
.touchMenuItemButton {
  border-bottom: solid 1px transparent;
  display: inline-flex;
  line-height: spacing(3);
  padding: spacing(1.5) 0 10px;
  text-decoration: none;
}

.touchMenuItemButton {
  background-color: transparent;
  border: none;
  border-bottom: solid 1px transparent;
  font-weight: $font-weight-regular;
}
