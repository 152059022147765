@import '~@JohnLewisPartnership/jl-design-system/dist/style/typography/display-as';
@import '~@JohnLewisPartnership/jl-design-system/dist/style/colour/john-lewis/variables';
@import '~@JohnLewisPartnership/jl-design-system/dist/scss/core/spacing';
@import '~@JohnLewisPartnership/jl-design-system/dist/scss/grid/grid';
@import '~@JohnLewisPartnership/jl-design-system/dist/scss/grid/breakpoints';
@import '../../../scss/utilities.scss';

@import '../../../scss/base.scss';

.storeFinder {
  list-style-type: none;
  border-bottom: 1px solid $colour-grey-15;
  background-color: $colour-taupe-20;
  margin: 0;
  position: relative;
  cursor: pointer;

  &__button {
    @include display-as('heading-xs');
    display: block;
    text-decoration: none;
    padding: 13px spacing(2) 13px 42px;
    background-color: transparent;
    border: 0;
    cursor: pointer;
    width: 100%;
    text-align: left;
    -webkit-overflow-scrolling: touch;
    -webkit-tap-highlight-color: rgba(0, 0, 0, 0);

    &:before {
      content: '';
      position: absolute;
      top: 50%;
      height: 24px;
      width: 24px;
      margin-top: -11px;
      left: spacing(1.5);
      @include svg-background(
        '#{$svgLoadPathAssets}/icons-jb/pin/pin-24px-outlined.svg',
        $colour-black
      );
    }
  }
}
