@import '~@JohnLewisPartnership/jl-design-system/dist/style/typography/display-as';
@import '~@JohnLewisPartnership/jl-design-system/dist/style/colour/john-lewis/variables';
@import '~@JohnLewisPartnership/jl-design-system/dist/scss/core/spacing';
@import '~@JohnLewisPartnership/jl-design-system/dist/scss/grid/grid';
@import '~@JohnLewisPartnership/jl-design-system/dist/scss/grid/breakpoints';
@import '../../scss/utilities.scss';

@import '../../scss/base.scss';

.headerLinkItem {
  display: inline-block;
  margin-bottom: 0;
  border: none;
  background: none;
  color: $colour-black;
  text-decoration: none;

  &__prenav {
    display: flex;
    align-items: center;
    &:hover {
      cursor: pointer;
      text-decoration: none;
    }
  }

  &:hover {
    cursor: pointer;
    & * {
      text-decoration: underline;
    }
  }
  &:focus + .prenavLinkMenuBox {
    display: block;
  }
}

.underlinedHeaderLinkItem {
  &:hover {
    text-decoration: underline;
    cursor: pointer;
  }
  &__prenav {
    &:hover {
      color: $colour-black;
      text-decoration: none;
    }
  }
}

.prenavLinkMenuBox {
  position: absolute;
  width: 200px;
  right: 0px;
  z-index: 101;
  display: block;
  transition: all 0.2s ease-in-out;
  top: 25px;
}

.headerLinksContainer {
  position: relative;
  padding-left: spacing(2) + 4;
  margin: 0;

  &__prenav {
    padding: 0;
    margin-left: spacing(2) + 4;
    border-bottom: 2px solid $colour-grey-5;
    &:hover {
      border-bottom: 2px solid $colour-black;
    }
  }
}

.menuChevronIcon {
  width: 12px;
  height: 14px;
  margin-left: 4px;
}
