@import '~@JohnLewisPartnership/jl-design-system/dist/style/typography/display-as';
@import '~@JohnLewisPartnership/jl-design-system/dist/style/colour/john-lewis/variables';
@import '~@JohnLewisPartnership/jl-design-system/dist/scss/core/spacing';
@import '~@JohnLewisPartnership/jl-design-system/dist/scss/grid/grid';
@import '~@JohnLewisPartnership/jl-design-system/dist/scss/grid/breakpoints';
@import '../../scss/utilities.scss';

@import '../../scss/base';

.links {
  color: $colour-grey-56;
  font-size: 12px;
  font-weight: $font-weight-regular;
  line-height: 24px;
  list-style: none;
  margin-bottom: 0;

  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  align-items: center;
  padding: 3px 0 0;
  height: 24px;
  flex: none;
  order: 0;
  flex-grow: 0;

  &__prenav {
    justify-content: right;
    max-width: 1200px;
    margin: auto;
    padding: 0;
    padding-right: 22px;

    @include breakpoint(large) {
      padding-right: 10px;
    }
  }
}

.skip {
  @include visuallyHidden;
  clip: inherit;
  width: 100%;
  overflow: visible;
  padding-left: 0;
  height: 0;
}
