@import '~@JohnLewisPartnership/jl-design-system/dist/style/typography/display-as';
@import '~@JohnLewisPartnership/jl-design-system/dist/style/colour/john-lewis/variables';
@import '~@JohnLewisPartnership/jl-design-system/dist/scss/core/spacing';
@import '~@JohnLewisPartnership/jl-design-system/dist/scss/grid/grid';
@import '~@JohnLewisPartnership/jl-design-system/dist/scss/grid/breakpoints';
@import '../../scss/utilities.scss';

@import '../../scss/base.scss';

.headerLinkItem {
  display: inline-block;
  margin-bottom: 0;
  padding-left: spacing(2);

  &:first-child {
    padding-left: 0;
  }
}

.headerLink {
  display: block;
  padding-top: 2px;
  padding-bottom: 6px;
  padding-left: 0;
  font-size: 12px;
  text-decoration: none;
  color: $colour-black;
  line-height: 20px;
  font-weight: $font-weight-regular;

  & * {
    text-decoration: none;
  }

  &:hover {
    text-decoration: underline;

    & * {
      text-decoration: underline;
    }
  }

  &__prenav {
    font-size: 14px;
  }
}
