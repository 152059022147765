@import '~@JohnLewisPartnership/jl-design-system/dist/style/typography/display-as';
@import '~@JohnLewisPartnership/jl-design-system/dist/style/colour/john-lewis/variables';
@import '~@JohnLewisPartnership/jl-design-system/dist/scss/core/spacing';
@import '~@JohnLewisPartnership/jl-design-system/dist/scss/grid/grid';
@import '~@JohnLewisPartnership/jl-design-system/dist/scss/grid/breakpoints';
@import '../../scss/utilities.scss';

@import '../../scss/base';

@mixin alignCenter {
  display: flex;
  align-items: center;
}

.container {
  @include alignCenter;
}

.desktop {
  display: none;
  @include breakpoint(medium) {
    display: flex;
    align-items: center;
  }

  &__tn {
    @include breakpoint(medium) {
      display: none;
    }

    @include breakpoint(1200) {
      display: flex;
    }
  }
}

.mobile {
  display: flex;
  @include breakpoint(medium) {
    display: none;
  }
}

.account {
  position: relative;

  @include breakpoint(medium) {
    min-width: 80px;
    justify-content: right;
  }

  &__tn {
    @include breakpoint(medium) {
      min-width: initial;
      justify-content: normal;
    }

    @include breakpoint(1200) {
      min-width: 80px;
      justify-content: right;
    }
  }
}

.signIn {
  padding-right: 0;

  a {
    color: $colour-black;
    text-decoration: none;

    &:hover {
      text-decoration: underline;
    }
  }
}

.menuTrigger {
  @include breakpoint(medium) {
    height: spacing(6);
  }

  &__tn {
    @include breakpoint(medium) {
      height: auto;
    }

    @include breakpoint(1200) {
      height: spacing(6);
    }
  }
}

.icon {
  padding: spacing() spacing(2) spacing() spacing();
  height: spacing(6);
  @extend %jb-icon-height;

  &__mobile {
    @include breakpoint-between(0, medium) {
      padding: 0 0 0 spacing(2);
      height: auto;
      text-decoration-line: none;
    }
  }

  @include breakpoint(medium) {
    padding-right: 0;
    margin-right: 18px;
    min-width: 32px;
  }
}

.label {
  @extend %nav-icon-label;

  &__tn {
    @include breakpoint(medium) {
      display: block;
    }

    @include breakpoint(1200) {
      display: none;
    }
  }
}
