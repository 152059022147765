@import '~@JohnLewisPartnership/jl-design-system/dist/style/typography/display-as';
@import '~@JohnLewisPartnership/jl-design-system/dist/style/colour/john-lewis/variables';
@import '~@JohnLewisPartnership/jl-design-system/dist/scss/core/spacing';
@import '~@JohnLewisPartnership/jl-design-system/dist/scss/grid/grid';
@import '~@JohnLewisPartnership/jl-design-system/dist/scss/grid/breakpoints';
@import '../../scss/utilities.scss';

@import '../../scss/base';
@import './typeahead-transition-utils.scss';

$headerHeight_Mobile: 130px;
$headerHeight_Tablet: 82px;
$headerHeight_Desktop: 170px;

$headerHeight_Mobile_BU: 124px;
$headerHeight_Tablet_BU: 76px;
$headerHeight_Desktop_BU: 164px;

// @TODO once we've rolled these styles out in every app, refactor this code
// in with the rest and delete the height attributes
:global(.jl-esi-header) {
  .container {
    position: relative;
  }
}

:root {
  --jl-header-height-peep: 128px;
  --jl-header-height: #{$headerHeight_Mobile_BU};

  @include breakpoint(small) {
    --jl-header-height: #{$headerHeight_Tablet_BU};
  }

  @include breakpoint(medium) {
    --jl-header-height: #{$headerHeight_Desktop_BU};
  }
}

.header {
  display: block;
  position: relative;
  top: 0;
  width: 100%;
  z-index: 11;

  height: $headerHeight_Mobile_BU;

  @include breakpoint(small) {
    height: $headerHeight_Tablet_BU;
  }

  @include breakpoint(medium) {
    height: auto;
  }

  &__tn {
    @include breakpoint(medium) {
      --jl-header-height: #{$headerHeight_Tablet_BU};
      height: $headerHeight_Tablet;
    }

    @include breakpoint(1200) {
      --jl-header-height: #{$headerHeight_Desktop_BU};
      height: auto;
    }
  }

  &__app_banner {
    --jl-header-height: 203px;
    height: 203px;

    @include breakpoint(small) {
      --jl-header-height: 155px;
      height: 155px;
    }

    @include breakpoint(medium) {
      height: auto;
    }
  }

  .container {
    border-bottom: 1px solid $colour-grey-15;
    @include breakpoint(medium) {
      height: auto !important;
      border-bottom: none;
    }

    &__tn {
      border-bottom: none;
    }
  }

  &.navOpenPersistent {
    pointer-events: all;
    position: fixed;

    .container {
      border-bottom: 1px solid $colour-grey-15;
      transition: none;
      transform: none;

      &__tn {
        border-bottom: none;
      }
    }

    .search {
      @include breakpoint(small) {
        min-height: 48px;
      }
    }
  }
}

.topBar {
  display: none;
  height: 24px;
  background: $colour-taupe-20;
  transition: height 0.3s;

  @include breakpoint(medium) {
    display: block;
  }

  &__active {
    height: 0;
  }

  &__tn {
    @include breakpoint(medium) {
      display: none;
    }

    @include breakpoint(1200) {
      display: block;
    }
  }
}

.topLinks {
  margin: 0 auto;
  max-width: 1200px;
  padding: 0 spacing(2);
  text-align: right;

  @include breakpoint(large) {
    padding-right: 0;
  }

  &__prenav {
    max-width: none;
    padding: 0;
  }

  &__tn {
    @include breakpoint(1200) {
      width: calc(100vw - 48px);
      max-width: 1320px;
      padding-left: 0;
      padding-right: 0;
    }

    @include breakpoint(large) {
      width: calc(100vw - 64px);
      padding-left: 0;
      padding-right: 0;
    }
  }
}

.container {
  border-bottom: 1px solid $colour-grey-15;

  @include breakpoint-between(0, medium) {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    z-index: 101;
  }

  &.peepHeader {
    transition: transform 0.5s ease-in-out;
    transform: translate3d(0, 0, 0);
    pointer-events: all;
  }

  &.peep {
    transform: translate3d(0, -100%, 0);
    transition-duration: 0.25s;
  }

  &__tn {
    @include breakpoint-between(0, 1200) {
      position: fixed;
      top: 0;
      left: 0;
      width: 100%;
      z-index: 101;
    }
  }

  &__app_banner {
    transition: top 0.3s !important;

    &__visible {
      @include breakpoint-between(0, medium) {
        top: 79px;
      }
    }
  }
}

.logoRow {
  position: relative;
  align-items: top;
  background: $colour-white;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  width: 100%;
  z-index: 100;
  max-width: 1200px;
  margin: 0 auto;
  padding-top: 18px;

  @include breakpoint(small) {
    padding-left: spacing(3);
    padding-bottom: spacing() + 4;
    padding-top: 16px;
  }

  @include breakpoint(medium) {
    align-items: center;
    border-bottom: 0;
    padding-top: spacing(2) + 3;
    padding-bottom: spacing(2) + 1;
  }

  @include breakpoint(large) {
    padding-left: spacing(2);
  }

  &__active {
    padding-left: 0;

    @include breakpoint(small) {
      padding-top: 16px;
      padding-bottom: 12px;
      padding-left: 8px;
    }

    @include breakpoint(medium) {
      justify-content: space-between;
      padding: 18px 24px 19px;
    }

    @include breakpoint(large) {
      padding-left: 16px;
    }
  }

  &__tn {
    @include breakpoint(small) {
      align-items: center;
    }

    @include breakpoint(medium) {
      border-bottom: 0;
      padding-top: spacing(2);
      padding-bottom: spacing() + 4;
    }

    @include breakpoint(1200) {
      align-items: center;
      padding-top: 17px;
      padding-bottom: 13px;
      padding-left: 0;
      padding-right: 0;
      width: calc(100vw - 48px);
      max-width: 1320px;
    }

    @include breakpoint(large) {
      width: calc(100vw - 64px);
      padding-left: 0;
      padding-right: 0;
    }
  }

  &__grouped {
    @include breakpoint(large) {
      padding-left: 0;
    }
  }
}

.search {
  position: relative;
  z-index: 110;
  @include grid-col(5);
  margin: spacing(2) 0 0;
  order: 1;
  width: 100%;
  transition: none;
  padding-left: spacing(2);
  padding-right: spacing(2);

  @include breakpoint(small) {
    width: calc(50% - 60px);
    order: 0;
    margin: 0;
    margin-right: 0 !important;
    padding: 0;
  }

  @include breakpoint(900) {
    width: 480px;
  }

  @include breakpoint(large) {
    width: 520px;
  }

  @include breakpoint(1440) {
    width: 560px;
  }
}

.expandedSearch {
  z-index: 126;
  margin-right: auto !important;
  width: 100%;
  margin-top: 16px;
  margin-left: 0;
  padding-left: 16px;
  padding-right: 16px;
  order: 2;
  transition: width $typeaheadOpeningTransDur $typeaheadTransTiming;

  @include breakpoint(small) {
    padding-left: 0;
    padding-right: 0;
    order: 1;
    margin-top: 0;
    min-width: 677px;
    width: auto;
  }

  @include breakpoint(medium) {
    min-width: 750px;
    padding-right: 0;
    flex: 0 1 74%;
    margin-right: 0 !important;
  }

  @include breakpoint(large) {
    min-width: 897px;
  }
}

:global(#mobile-search:target) {
  margin-top: 0;
}

.overlay {
  background-color: #000;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 120%;
  cursor: pointer;
  z-index: 1;
  opacity: 0;
  transition: opacity 0.5s ease-in-out;

  &.showOverlay {
    opacity: 0.75;
  }
}

:global(.lockBody) {
  @include breakpoint-between(0, medium) {
    height: 100%;
    width: 100%;
    overflow: hidden;
  }
}

.typeaheadOpen {
  overflow: hidden;
  position: fixed;
  width: 100%;
}

.typeaheadSeparator {
  position: absolute;
  z-index: 130;
  width: 100vw;
  border: none;
  border-top: 1px solid $colour-grey-15;
  margin: 0;
  visibility: visible;
  transition: opacity $typeaheadOpeningTransDur $typeaheadTransTiming;

  &__hidden {
    width: 0;
    visibility: hidden;
    opacity: 0;

    &__doTransition {
      transition-property: opacity, visibility;
      transition-duration: $typeaheadClosingTransDur, 0s;
      transition-delay: 0s, $typeaheadClosingTransDur;
    }
  }

  @include breakpoint-between(0, medium) {
    display: none;
  }
}

.greenBar {
  width: 100%;
  height: 6px;
  border-bottom: solid;
  border-bottom-width: 6px;
  border-bottom-color: $colour-partner-green;
  position: relative;
  display: none;
}
