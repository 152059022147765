@import '~@JohnLewisPartnership/jl-design-system/dist/style/typography/display-as';
@import '~@JohnLewisPartnership/jl-design-system/dist/style/colour/john-lewis/variables';
@import '~@JohnLewisPartnership/jl-design-system/dist/scss/core/spacing';
@import '~@JohnLewisPartnership/jl-design-system/dist/scss/grid/grid';
@import '~@JohnLewisPartnership/jl-design-system/dist/scss/grid/breakpoints';
@import '../../scss/utilities.scss';

@import '../../scss/base';

.visuallyHidden {
  @include visuallyHidden;
}

.icon {
  align-items: center;
  background: $colour-black;
  border-radius: 8px;
  color: $colour-white;
  display: flex;
  font-size: 10px;
  font-weight: $font-weight-regular;
  height: spacing(2);
  justify-content: center;
  position: absolute;
  text-align: center;
  width: spacing(2);
  z-index: 1;
  top: 0;
  right: -3px;

  @include breakpoint(medium) {
    top: 9px;
    right: 3px;
  }

  &__tn {
    top: -2px;

    @include breakpoint(1200) {
      top: 9px;
      right: -4px;
    }
  }
}
