@import '~@JohnLewisPartnership/jl-design-system/dist/style/typography/display-as';
@import '~@JohnLewisPartnership/jl-design-system/dist/style/colour/john-lewis/variables';
@import '~@JohnLewisPartnership/jl-design-system/dist/scss/core/spacing';
@import '~@JohnLewisPartnership/jl-design-system/dist/scss/grid/grid';
@import '~@JohnLewisPartnership/jl-design-system/dist/scss/grid/breakpoints';
@import '../../scss/utilities.scss';

@import '../../scss/base';

.searchContainer {
  position: relative;

  input {
    border: 1px solid $colour-grey-56;
    border-radius: 2px;
    transition: border-radius 0.12s linear, border-color 0.12s linear;

    &:focus {
      outline: none;
    }
  }

  &:hover,
  &:active,
  &:focus {
    input {
      border: 2px solid $colour-black;
      border-radius: 4px;
      padding-top: 12px;
      padding-bottom: 13px;
      text-indent: 15px !important;
    }
  }

  &__active {
    &:hover {
      input {
        text-indent: 14px !important;
      }
    }
  }

  &__tn {
    @include breakpoint-between(0, 1200) {
      input {
        padding-left: 40px !important;
        padding-right: 40px !important;
      }
    }
  }
}

.searchInputContainer {
  flex-grow: 1;
  padding: 0;
  margin-bottom: 10px;

  @include breakpoint(small) {
    margin-bottom: 0px;
  }
}

.glassIconContainer {
  position: absolute;
  left: 12px;
  top: 0;
  height: 100%;
  display: flex;
}

.closeIconContainer {
  position: absolute;
  right: 12px;
  top: 0px;
  height: 100%;
  display: flex;

  @include breakpoint-between(0, medium) {
    right: 16px;
    width: 32px;
  }
}

.searchInput {
  border: none;
  border-radius: 2px;
  padding: 13px 8px 14px;
  font-size: $font-size-base;
  font-weight: $font-weight-regular;
  width: 100%;
  text-align: center;
  line-height: 1;
  padding-right: 10px !important;
  text-indent: spacing(2)-1 !important;
  transition: border-radius 0.12s linear, border-color 0.12s linear, width 0.3s;

  @include breakpoint-between(0, medium) {
    padding-left: 102px !important;
  }

  &__active {
    text-align: left;
    padding-left: 30px !important;
    padding-right: 30px !important;
    border-radius: 4px;
  }
}

button.searchBarButton {
  margin-bottom: 0;
  padding-left: 0;
  padding-right: 0;
  background-color: transparent;
  min-width: spacing(4);
  &:hover {
    background-color: transparent;
  }
}

.visuallyHidden {
  @include visuallyHidden;
}

.hide {
  display: none !important;
}

html body .searchContainer input[type='text'] {
  @include breakpoint-between(0, medium) {
    padding-left: 40px !important;
    padding-right: 40px !important;
  }
}

// html body .searchContainer__tn input[type='text'] {
//   @include breakpoint-between(0, 1200) {
//     padding-left: 40px !important;
//     padding-right: 40px !important;
//   }
// }
