@import '~@JohnLewisPartnership/jl-design-system/dist/style/typography/display-as';
@import '~@JohnLewisPartnership/jl-design-system/dist/style/colour/john-lewis/variables';
@import '~@JohnLewisPartnership/jl-design-system/dist/scss/core/spacing';
@import '~@JohnLewisPartnership/jl-design-system/dist/scss/grid/grid';
@import '~@JohnLewisPartnership/jl-design-system/dist/scss/grid/breakpoints';
@import '../../scss/utilities.scss';

@import '../../scss/base';

.skipLinkItem {
  display: inline-block;
  list-style: none;
}

.skipLink {
  @include visuallyHidden;

  &:focus {
    clip: auto;
    height: auto;
    width: auto;
    top: spacing(0.5);
    margin: 0;
    margin-left: spacing(2);
    padding: spacing();
    background: $colour-white;
    border: 1px solid $colour-grey-15;
    line-height: $font-size-base;
    z-index: 125;
  }
}
