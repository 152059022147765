@import '~@JohnLewisPartnership/jl-design-system/dist/style/typography/display-as';
@import '~@JohnLewisPartnership/jl-design-system/dist/style/colour/john-lewis/variables';
@import '~@JohnLewisPartnership/jl-design-system/dist/scss/core/spacing';
@import '~@JohnLewisPartnership/jl-design-system/dist/scss/grid/grid';
@import '~@JohnLewisPartnership/jl-design-system/dist/scss/grid/breakpoints';
@import '../../scss/utilities.scss';

@import '../../scss/base';

.visuallyHidden {
  @include visuallyHidden;
}

.formButtons {
  position: absolute;
  top: 0;
  right: 0;
  display: flex;
  height: 100%;
  padding: 1px 0;

  button[type='submit'],
  button[type='reset'] {
    width: spacing(5);
    min-width: spacing(5);
    height: 100%;
    padding: 0 spacing(1);
    border: none;
    background-color: transparent;

    @include breakpoint-between(0, medium) {
      width: (spacing(6) + spacing(0.5)) - 1 !important;
    }

    &:hover {
      background-color: $colour-grey-5;
    }

    & :first-child {
      width: 100%;
    }
  }

  button[type='submit'] {
    padding-right: spacing(1)-1px;
    border-right: 1px solid $colour-black;
  }
}

.hide {
  display: none;

  @include breakpoint(small) {
    display: block;
    opacity: 0;
    visibility: hidden;
  }
}

.form {
  background-color: $colour-white;
  transition: transform 0.3s;

  @include breakpoint(medium) {
    display: flex;
    justify-content: space-between;
    flex-direction: row;
    flex-grow: 1;
    margin-top: 0;
  }

  &__expanded {
    @include breakpoint(medium) {
      min-width: 677px;
      flex-grow: 0;
    }

    @include breakpoint(large) {
      min-width: 738px;
    }
  }

  form {
    margin: 0;

    &:active {
      input[type='text'] {
        text-indent: spacing(2)-1px;
      }

      .formButtons {
        padding: 2px 0;

        button[type='submit'] {
          padding-right: spacing(1)-2px;
        }
      }
    }
  }

  label {
    @include visuallyHidden();
  }

  input[type='text'] {
    padding-left: 0;
    text-indent: spacing(2);
    padding-right: spacing(10);
    font-weight: $font-weight-regular;

    &::placeholder {
      color: $colour-grey-56;
      opacity: 1;
    }

    &:invalid {
      box-shadow: none;
    }

    &:focus,
    &:not([disabled]):hover,
    &:active {
      text-indent: spacing(2)-1px;
    }

    &:not([disabled]):hover,
    &:active {
      + .formButtons {
        padding: 2px 0;

        button[type='submit'] {
          padding-right: spacing(1)-2px;
          border-right: 2px solid $colour-black;
        }
      }
    }

    &:focus {
      + .formButtons {
        padding: 3px 0;

        button[type='submit'] {
          padding-right: spacing(1)-3px;
          border-right: 3px solid $colour-black;
        }
      }
    }

    @include breakpoint-between(0, medium) {
      padding-right: spacing(13) - 2 !important;
    }
  }

  input[type='text'].search-input-searchInput {
    border: none;
  }
}

.typeaheadResults {
  opacity: 1;

  @include breakpoint-between(0, medium) {
    width: 100%;
  }

  &__hidden {
    display: none;
    opacity: 0;
  }
}
