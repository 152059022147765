@import '~@JohnLewisPartnership/jl-design-system/dist/style/typography/display-as';
@import '~@JohnLewisPartnership/jl-design-system/dist/style/colour/john-lewis/variables';
@import '~@JohnLewisPartnership/jl-design-system/dist/scss/core/spacing';
@import '~@JohnLewisPartnership/jl-design-system/dist/scss/grid/grid';
@import '~@JohnLewisPartnership/jl-design-system/dist/scss/grid/breakpoints';
@import '../../scss/utilities.scss';

@import '../../scss/base.scss';

.overlayContainer {
  position: absolute;
  width: 360px;
  z-index: 12;
  right: 0 - spacing(11) + 5;
}

.overlay {
  display: none;
  position: relative;
  top: spacing() - 2;
  border: 1px solid $colour-grey-15;
  border-bottom: none;
  background: $colour-white;

  @include breakpoint(medium) {
    display: block;
  }

  &:after,
  &:before {
    bottom: 100%;
    left: 246px;
    border: solid transparent;
    content: ' ';
    height: 0;
    width: 0;
    position: absolute;
    pointer-events: none;
  }
  &:after {
    border-color: rgba(136, 183, 213, 0);
    border-bottom-color: $colour-white;
    border-width: 10px;
    margin-left: -10px;
  }
  &:before {
    border-color: rgba(194, 225, 245, 0);
    border-bottom-color: $colour-grey-15;
    border-width: 11px;
    margin-left: -11px;
  }
}

.overlayHeader {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 64px;

  h3 {
    margin: 0;
  }

  button {
    display: none;
    @include breakpoint(medium) {
      display: block;
    }
    @include breakpoint(large) {
      display: block;
    }
  }

  h2 {
    text-transform: none;
    margin-bottom: 0;
  }
}

.overlayBody {
  border-top: 1px solid $colour-grey-15;
  text-align: left;
}
