@import '~@JohnLewisPartnership/jl-design-system/dist/style/typography/display-as';
@import '~@JohnLewisPartnership/jl-design-system/dist/style/colour/john-lewis/variables';
@import '~@JohnLewisPartnership/jl-design-system/dist/scss/core/spacing';
@import '~@JohnLewisPartnership/jl-design-system/dist/scss/grid/grid';
@import '~@JohnLewisPartnership/jl-design-system/dist/scss/grid/breakpoints';
@import '../../../scss/utilities.scss';

@import '../../../scss/base.scss';

$large-breakpoint: 1440;

.tabbedFlyout {
  background-color: $colour-white;
  font-size: $font-size-body-medium;
  color: $colour-black;
  border-top: 1px solid $colour-grey-15;
  left: 50%;
  margin: 0 auto;
  position: absolute;
  transform: translateX(-50%);
  width: 100%;
  max-width: 1440px;

  li {
    list-style-type: none;
    text-align: left;
    margin-bottom: 0;
  }

  &
    div[id*='navigation-content']
    div[id*='content-root-content-renderer-navigation']
    div[class*='aem-kit-col']:not(:empty) {
    padding: spacing(4) spacing(3);

    @include breakpoint($large-breakpoint) {
      padding: spacing(4) 60px;
    }
  }
}

.tabbedContainer {
  background-color: $colour-white;
  display: flex;
  height: 396px;

  &[data-tabs-count='7'] {
    height: 462px;
  }

  &[data-tabs-count='8'] {
    height: 528px;
  }
}

.tabbedContentContainer {
  width: 100%;
  height: 100%;
}

.tabsListContainer {
  flex: 0 0 316px;
  height: 100%;

  @include breakpoint($large-breakpoint) {
    flex: 0 0 344px;
  }
}

.tabsList {
  padding: 0;
  margin: 0;
  position: relative;
}

.tab {
  background-color: #e8e8e8;
  transition: background-color 0.25s;
  list-style-type: none;
  margin-bottom: 0;
  width: 100%;
  max-width: 316px;
  cursor: default;
  position: relative;
  border-bottom: 1px solid #dddddd;

  @include breakpoint($large-breakpoint) {
    max-width: 344px;
  }

  &:hover {
    background-color: $colour-grey-15;
  }

  button {
    border: none;
    line-height: 1;
    width: 100%;
    border-left: 4px solid transparent;
    background-color: transparent;
    padding: 25px 35px 24px 24px;
    text-align: left;
    display: block;
    text-decoration: none;
    cursor: default;
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;

    &:after {
      animation: append-animate 0.25s ease-in;
      animation-fill-mode: forwards;
      content: '';
      position: absolute;
      top: 50%;
      height: 12px;
      width: 13px;
      right: 20px;
      transform: translateY(-50%);
      @include svg-background(
        '#{$svgLoadPathAssets}/icons-jb/chevron-right/chevron-right-24px-outlined.svg',
        $colour-black
      );
    }

    @include breakpoint(large) {
      padding-left: 28px;
      padding-right: 32px;
    }

    @include breakpoint($large-breakpoint) {
      padding-left: 56px;
    }

    transition: font-weight 0.1s;
  }

  &__first {
    button {
      &:after {
        display: none;
      }
    }
  }

  &__active {
    background-color: $colour-white;

    width: 100%;
    will-change: transform;

    &:hover {
      background-color: $colour-white;
    }

    &:after {
      content: ' ';
      pointer-events: none;
      position: absolute;
      transform: none;
      background: none;
      will-change: transform;
    }

    &:first-child {
      &:after {
        display: none;
      }
    }

    button {
      transition: border 0.25s;
      padding-right: 24px;
      font-weight: 600;
      border-bottom: none;
      will-change: transform;
      border-left: 4px solid $colour-accent;

      @include breakpoint(large) {
        padding-right: 32px;
      }

      @include breakpoint($large-breakpoint) {
        padding-left: 56px;
      }

      &:after {
        display: none;
      }
    }
  }

  &__salesOffers {
    button {
      color: $colour-offers;
    }
  }
}

.tabbedContentList {
  padding: 20px 0 0 0;
  max-height: 100%;
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  align-content: flex-start;
  margin: 0;

  @include breakpoint(large) {
    padding-left: 20px;
  }

  li {
    padding-bottom: 20px;
    padding-right: 40px;
    padding-left: 60px;
    width: 296px;

    opacity: 0;
    animation: append-animate 0.3s linear 0.25s;
    animation-fill-mode: forwards;

    a {
      text-decoration: none;
      display: inline;
      border-bottom: 1px solid transparent;
      &:hover {
        border-bottom: 1px solid $colour-black;
      }
    }
  }
}

@keyframes append-animate {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
