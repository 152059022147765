@import '~@JohnLewisPartnership/jl-design-system/dist/style/typography/display-as';
@import '~@JohnLewisPartnership/jl-design-system/dist/style/colour/john-lewis/variables';
@import '~@JohnLewisPartnership/jl-design-system/dist/scss/core/spacing';
@import '~@JohnLewisPartnership/jl-design-system/dist/scss/grid/grid';
@import '~@JohnLewisPartnership/jl-design-system/dist/scss/grid/breakpoints';
@import '../../scss/utilities.scss';

@import '../../scss/base';
@import '../responsive-header/typeahead-transition-utils.scss';

.container {
  display: flex;
  align-items: center;
  line-height: 0;
  font-size: 0;
  transition: width $typeaheadClosingTransDur $typeaheadTransTiming;

  a {
    display: inline-block;
  }

  padding-left: spacing(2);

  @include breakpoint(small) {
    padding-left: 0;
  }

  @include breakpoint(medium) {
    width: calc(20% - 16px);
  }

  &__expandedSearch {
    order: 0;

    @include breakpoint(small) {
      display: none;
    }

    @include breakpoint(medium) {
      display: block;
      width: auto;
    }
  }

  &__tease {
    @include breakpoint(medium) {
      width: auto;
      margin-top: 4px;
      height: 32px;
    }
  }

  &__tn {
    @include breakpoint(medium) {
      width: auto;
    }

    @include breakpoint(large) {
      width: auto;
    }
  }
}

.logo {
  position: relative;
  height: auto;
  z-index: 1;
  width: 110px;

  @include breakpoint(xxs) {
    width: 132px;
  }

  @media screen and (min-width: 420px) {
    width: 154px;
  }

  @include breakpoint(medium) {
    width: 178px;
  }

  &__tn {
    @include breakpoint(medium) {
      width: 154px;
    }

    @include breakpoint(1200) {
      width: 178px;
    }
  }
}

.hidden {
  @include visuallyHidden;
}
