@import '~@JohnLewisPartnership/jl-design-system/dist/style/typography/display-as';
@import '~@JohnLewisPartnership/jl-design-system/dist/style/colour/john-lewis/variables';
@import '~@JohnLewisPartnership/jl-design-system/dist/scss/core/spacing';
@import '~@JohnLewisPartnership/jl-design-system/dist/scss/grid/grid';
@import '~@JohnLewisPartnership/jl-design-system/dist/scss/grid/breakpoints';
@import '../../scss/utilities.scss';

@import '../../scss/base.scss';

.item {
  border-bottom: 1px solid $colour-grey-15;
  display: table;
  list-style: none;
  margin: 0;
  width: 100%;
  position: relative;
}

.link {
  color: $colour-black;
  display: block;
  padding-left: spacing(2);
  padding-bottom: ($spacing-half + 4);
  padding-right: spacing(6);
  padding-top: ($spacing-half + 4);
  line-height: 24px;
  text-decoration: none;
  width: 100%;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
  &:focus {
    background-color: $colour-grey-5;
  }
  &:active {
    background-color: $colour-grey-5;
  }
}

.menu-link--with-children {
  background: url(src/assets/icons/plus.svg) no-repeat;
  background-position: calc(100% - #{spacing(2)}) (#{spacing(1)});
}

.shopAll {
  background: $colour-grey-5;
}
