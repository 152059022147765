@import '~@JohnLewisPartnership/jl-design-system/dist/style/typography/display-as';
@import '~@JohnLewisPartnership/jl-design-system/dist/style/colour/john-lewis/variables';
@import '~@JohnLewisPartnership/jl-design-system/dist/scss/core/spacing';
@import '~@JohnLewisPartnership/jl-design-system/dist/scss/grid/grid';
@import '~@JohnLewisPartnership/jl-design-system/dist/scss/grid/breakpoints';
@import '../../../scss/utilities.scss';

@import '../../../scss/base.scss';

.menuOverlayContainer {
  position: absolute;
  width: 100%;
  z-index: 12;
  right: 0 - spacing(11) + 5;
}

.menuOverlay {
  display: none;
  position: relative;
  box-shadow: 0px 0px 16px 0px #{$colour-black}24;
  background: $colour-white;

  @include breakpoint(medium) {
    display: block;
  }
}

.menuOverlayBody {
  text-align: left;
  padding: 8px 0 12px 0;
  li {
    @include display-as('small-text-body');
  }
}

.item {
  display: table;
  list-style: none;
  margin: 0;
  width: 100%;
  position: relative;
  font-size: 16px;
}

.link {
  color: $colour-black;
  display: block;
  padding-left: spacing(2);
  padding-right: spacing(2);
  line-height: 34px;
  text-decoration: none;
  width: 100%;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
  &:focus {
    background-color: $colour-grey-5;
  }
  &:active {
    background-color: $colour-grey-5;
  }
  &:hover {
    color: $colour-grey-56;
  }
}
